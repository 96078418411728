import React from 'react'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockContent from '../block-content/block-content'
import * as styles from './page-intro.module.scss'

const PageIntro = ({page}) => {

  const catArray = page.categories?.map((cat) => cat.title)

  return (
    <div className={styles.pageIntro}>
      <div className={styles.pageIntroImageHolder} data-animate-in>
        <div className={styles.pageIntroImage} style={{ backgroundImage: `url(${imageUrlFor(buildImageObj(page._rawMainImage)).width(1200).fit('fill').url()})` }} aria-label={page._rawMainImage.alt} />
      </div>

      <div className={styles.pageIntroContentHolder}>
        <div className={styles.pageIntroContent} data-animate-in>
          <h1>{page.title}</h1>
          <div className={styles.introBlurb}>
            <BlockContent blocks={page._rawIntro} />
          </div>

          {catArray?.length > 0 && <span className={styles.categories}>{catArray?.join(', ')}</span>}

          {
            page.cta_link && page.cta_title &&
              <a className={`btn ${styles.introBtn}`} target='_blank' href={page.cta_link}>{page.cta_title}</a>
          }
        </div>
      </div>
    </div>
  )
}

export default PageIntro
