import { graphql } from 'gatsby'
import React from 'react'
import BlockContent from '../components/block-content/block-content'
import PageIntro from '../components/page-intro/page-intro'
import PrevNext from '../components/prev-next/prev-next'
import SEO from '../components/seo/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    site: sanitySiteSettings {
      keywords
      title
      description
    }

    page: sanityPage(id: {eq: $id}) {
      id
      title
      excerpt
      _rawMainImage
      _rawIntro
      _rawBody
      cta_link
      cta_title
      categories {
        title
      }
    }
  }
`

const PageTemplate = (props) => {
  const { data, errors } = props
  const { page, site } = data

  return (
    <Layout>
      <SEO title={page.title || site.title } description={page.excerpt} image={page._rawMainImage} />
      <div className='content-wrapper'>
        <PageIntro page={page} />
        <div className='page-content'>
          <BlockContent blocks={page._rawBody} inPage />
        </div>
        <PrevNext prev={props?.pageContext?.previous} next={props?.pageContext?.next} />
      </div>
    </Layout>
  )
}

export default PageTemplate
